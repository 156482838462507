<template>
  <div class="addUpdate">
    <el-dialog
      :title="state.title"
      width="640px"
      :before-close="cancel"
      :close-on-click-modal="false"
      v-model="state.visible"
    >
      <span class="headline" v-if="state.title == '添加子账号'"
        >添加子用户登录信息</span
      >
      <span class="headline" v-else>编辑子账号登录信息</span>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="editForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名：" prop="loginName">
          <span v-if="state.title == '添加子账号'">{{
            ruleForm.loginName + nums
          }}</span>
          <span v-if="state.title == '编辑子账号'">{{
            ruleForm.loginName
          }}</span>
        </el-form-item>
        <el-form-item label="手机号：" prop="tel">
          <el-input
            :disabled="state.editDisableAccount"
            size="medium"
            style="width: 80%"
            placeholder="请输入手机号"
            v-model="ruleForm.tel"
            :show-password="phoneShowPassword"
            @focus="phoneFocus"
          >
            <template
              #suffix
              v-if="state.title == '编辑子账号' && state.showEdit"
            >
              <i
                @click.prevent="iconClickAccount"
                class="iconfont icon-zizhanghaoxinxi_bianji"
                style="color: #435b8e; font-size: 14px"
                >编辑</i
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="登录密码：" prop="password">
          <el-input
            :disabled="state.editDisablePass"
            :show-password="showPassword"
            size="medium"
            style="width: 80%"
            placeholder="请输入登录密码"
            v-model="ruleForm.password"
          >
            <template
              #suffix
              v-if="state.title == '编辑子账号' && state.showEditPass"
            >
              <i
                @click.prevent="iconClickPass"
                class="iconfont icon-zizhanghaoxinxi_bianji"
                style="color: #435b8e; font-size: 14px"
                >编辑</i
              >
            </template></el-input
          >
        </el-form-item>
        <p class="headline second-permission">设置子用户权限</p>
        <el-form-item label="权限设置：">
          <el-button size="medium" @click="openInnerVisible"
            ><i class="iconfont icon-zizhanghaoxinxi_quanxianxuanze"></i>
            选择权限</el-button
          >
        </el-form-item>
      </el-form>
      <el-dialog
        width="640px"
        :title="`权限分配 - ${ruleForm.loginName}`"
        v-model="state.innerVisible"
        append-to-body
        custom-class="subfield-popper"
        :close-on-click-modal="false"
      >
        <div class="all">
          <span>全部开启</span>
          <el-switch
            @change="allOpen"
            v-model="state.allOpen"
            active-color="#F04848"
            inactive-color="#D0D3D9"
          ></el-switch>
        </div>
        <div class="subfield">
          <span class="subfield_title"
            ><i class="iconfont icon-celan_hangyefenxi"></i> 行业分析</span
          >
          <span class="subfield_title" style="margin-left: 30px"></span>
          <div class="hangyepaihang">
            <span>行业排行</span>
            <el-radio-group
              fill="#F04848"
              @change="selHangye"
              size="mini"
              v-model="state.hangye"
            >
              <el-radio-button :disabled="state.disables" :label="1"
                >全部</el-radio-button
              >
              <el-radio-button :disabled="!state.disables" :label="0"
                >自定义</el-radio-button
              >
            </el-radio-group>
          </div>
          <div v-for="(item, index) in state.items" :key="item.nowTime">
            <child
              @selHangye="selHangye"
              :item="item"
              :sendChild="state.sendChild"
              :openCheckAll="state.openCheckAll"
              :cardList="state.items"
              :switchList="state.switchList"
              :sjqxList="state.sjqxList"
              :showCard="state.showCard"
              @addShow="addShow"
              @itemCon="itemCon"
              @delChild="delChild(index)"
              :showType="state.showType"
              @selChildCard="selChildCard"
              @delChildCard="delChildCard"
              ref="child"
            ></child>
          </div>
          <div class="download_all">
            <span>数据下载</span>
            <el-switch
              size="small"
              v-model="state.download_all"
              active-color="#F04848"
              inactive-color="#D3D3D3"
            >
            </el-switch>
          </div>
        </div>
        <div class="subfield">
          <span class="subfield_title"
            ><i class="iconfont icon-celan_pinpaifenxi"></i> 品牌分析</span
          >
          <div class="listStyle">
            <span>品牌排行</span>
            <el-switch
              :disabled="state.switchList.brand_ranking === 0"
              size="small"
              v-model="state.brand_ranking"
              active-color="#F04848"
              inactive-color="#D3D3D3"
            >
            </el-switch>
          </div>
          <div class="listStyle">
            <span>品牌关注</span>
            <el-switch
              :disabled="state.switchList.brand_ranking_follow_state === 0"
              size="small"
              v-model="state.brand_ranking_follow_state"
              active-color="#F04848"
              inactive-color="#D3D3D3"
            >
            </el-switch>
          </div>
        </div>
        <div class="subfield">
          <span class="subfield_title"
            ><i class="iconfont icon-celan_dianpufenxi"></i> 店铺分析</span
          >
          <div class="listStyle">
            <span>店铺排行</span>
            <el-switch
              :disabled="state.switchList.shop_ranking === 0"
              size="small"
              v-model="state.shop_ranking"
              active-color="#F04848"
              inactive-color="#D3D3D3"
            >
            </el-switch>
          </div>
          <div class="listStyle">
            <span>店铺关注</span>
            <el-switch
              :disabled="state.switchList.shop_ranking_follow_state === 0"
              size="small"
              v-model="state.shop_ranking_follow_state"
              active-color="#F04848"
              inactive-color="#D3D3D3"
            >
            </el-switch>
          </div>
          <div class="listStyle">
            <span>促销详情</span>
            <el-switch
              size="small"
              :disabled="state.switchList.shop_details_promotion === 0"
              v-model="state.shop_details_promotion"
              active-color="#F04848"
              inactive-color="#D3D3D3"
            >
            </el-switch>
          </div>
          <div class="listStyle">
            <span>店铺详情</span>
            <el-switch
              :disabled="state.switchList.shop_details === 0"
              size="small"
              v-model="state.shop_details"
              active-color="#F04848"
              inactive-color="#D3D3D3"
            >
            </el-switch>
          </div>
          <div class="listStyle">
            <span>店铺详情数据下载</span>
            <el-switch
              :disabled="state.switchList.shop_download === 0"
              size="small"
              v-model="state.shop_download"
              active-color="#F04848"
              inactive-color="#D3D3D3"
            >
            </el-switch>
          </div>
        </div>
        <div class="subfield">
          <span class="subfield_title"
            ><i class="iconfont icon-celan_shangpinfenxi"></i> 商品分析</span
          >
          <div class="listStyle">
            <span>商品排行</span>
            <el-switch
              :disabled="state.switchList.pro_ranking === 0"
              size="small"
              v-model="state.pro_ranking"
              active-color="#F04848"
              inactive-color="#D3D3D3"
            >
            </el-switch>
          </div>
          <div class="listStyle">
            <span>数据下载</span>
            <el-switch
              :disabled="state.switchList.pro_details_download === 0"
              size="small"
              v-model="state.pro_details_download"
              active-color="#F04848"
              inactive-color="#D3D3D3"
            >
            </el-switch>
          </div>
          <div class="listStyle">
            <span>商品详情</span>
            <el-switch
              :disabled="state.switchList.pro_details === 0"
              size="small"
              v-model="state.pro_details"
              active-color="#F04848"
              inactive-color="#D3D3D3"
            >
            </el-switch>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="medium" @click="cancelInnerDialog"
              >取 消</el-button
            >
            <el-button type="primary" @click="saveChild" size="medium"
              >确 认</el-button
            >
          </span>
        </template>
      </el-dialog>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="medium" @click="cancel">取 消</el-button>
          <el-button type="primary" size="medium" @click="save"
            >添 加</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, watch, defineComponent, ref } from 'vue'
import child from './child'
import axios from '@/utils/request'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
export default defineComponent({
  components: {
    child
  },
  props: {
    nums: {
      type: Number
    },
    modelValue: Boolean,
    tableData: Array,
    title: String,
    row: Object
  },
  // ['modelValue', 'title', 'tableData', 'row', 'nums'],
  setup (props, ctx) {
    // 定义弹窗数据
    const state = reactive({
      allOpen: false,
      download_all: false,
      disables: false,
      showEditPass: false,
      disables2: false,
      showEdit: false,
      items: [
        {
          nowTime: new Date().getTime()
        }
      ],
      selCard: [],
      switchList: {},
      menuList: [],
      title: '添加子账号',
      openCheckAll: false,
      checkedList: [],
      visible: false,
      innerVisible: false,
      brand_ranking: false,
      brand_ranking_follow_state: false,
      shop_ranking: false,
      shop_ranking_follow_state: false,
      shop_details: false,
      shop_details_promotion: false,
      pro_details: false,
      shop_download: false,
      pro_ranking: false,
      hangye: 1,
      showType: false,
      showBtn: false,
      pro_details_download: false,
      sjqxList: [],
      permission_industry: [],
      permissions: {},
      showCard: true,
      selChildCard: [],
      editDisableAccount: false,
      editDisablePass: false
    })

    const store = useStore()
    const stateVuex = store.state
    const ruleForm = reactive({
      tel: '',
      password: '',
      loginName: stateVuex.username + '-'
    })
    const iconClickAccount = () => {
      state.editDisableAccount = false
      state.showEdit = false
    }
    const iconClickPass = () => {
      state.editDisablePass = false
      state.showEditPass = false
    }
    // 拿到子组件传过来的数据
    const itemCon = (item) => {
      state.menuList.push(item)
      // 数组去重
      var obj = {}
      state.menuList = state.menuList.reduce(function (item, next) {
        // eslint-disable-next-line no-unused-expressions
        obj[next.localId] ? '' : (obj[next.localId] = true && item.push(next))
        return item
      }, [])
    }
    const childs = ref(null)
    // 字符串截取方法
    const getCaption = (obj) => {
      var index = obj.lastIndexOf('-')
      obj = obj.substring(index + 1, obj.length)
      return obj
    }
    watch(
      () => [props.modelValue, props.title, props.tableData, props.nums],
      ([newValue, newTitle, newTableData]) => {
        state.visible = newValue
        state.title = newTitle
        if (newTitle === '编辑子账号') {
          ruleForm.password = '**************'
          state.editDisableAccount = true
          state.editDisablePass = true
          state.showEdit = true
          state.showEditPass = true
          phoneShowPassword.value = false
        } else {
          ruleForm.password = ''
          state.editDisableAccount = false
          state.editDisablePass = false
          state.showEdit = false
          state.showEditPass = false
        }
        if (newTableData.length > 0) {
          state.nums =
            Number(getCaption(newTableData[newTableData.length - 1].name)) +
            Number(1)
        }
      }
    )
    watch(
      () => state.visible,
      () => {
        if (state.visible) {
          getPermission()
        } else {
          resData.value = {}
          state.permissions = {}
          allOpen(false)
        }
      }
    )
    watch(
      () => state.innerVisible,
      () => {
        if (state.innerVisible) {
          setPermission(state.permissions)
          state.allOpen =
            !!state.permissions &&
            !!Object.keys(state.permissions).length &&
            Object.entries(state.permissions).every(([key, value]) => {
              return value || !state.switchList[key]
            })
        } else {
          state.allOpen = false
        }
      }
    )
    watch(state, () => {
      const isState = Object.entries(state.switchList).every(([key, value]) => {
        return key === 'industry_ranking' || state[key] || !value
      })
      state.allOpen = isState && state.items.length === state.selChildCard.length
    })

    const getPermission = () => {
      axios.get('/api/v1/customer/get_customer_permissions').then((res) => {
        if (res.error_code !== 0) return
        state.hangye = res.data.customer_permission.industry_data_sup_per
        if (state.hangye === 0) {
          state.showType = true
          axios.get('/api/v1/basedata/get_website').then((res) => {
            state.sjqxList = res.data
          })
        }
        state.industry_data_sup_per = state.hangye
        // 把父账号权限赋给子账号。switchList判断子账号是否有权限！！！！！！！！！
        state.switchList = res.data.customer_permission
        state.items = res.data.permission_industry
        state.disables = state.hangye === 0
      })
    }
    const selChildCard = (val) => {
      state.selChildCard.push(val)
    }
    const delChildCard = (val) => {
      // state.selCard.filter()
      state.selChildCard = state.selChildCard.filter((item) => {
        return item.id !== val.id
      })
    }
    // 保存
    const saveChild = () => {
      if (props.row.id) {
        // 是编辑时候的保存-----只保存父级id
        state.selChildCard.map((item) => {
          state.permission_industry.push(item.id)
        })
      } else {
        // 是新增时候的保存
        if (state.switchList.industry_data_sup_per === 0) {
          // state.permission_industry = state.selCard
          state.selChildCard.map((item) => {
            state.permission_industry.push(item.id)
          })
        } else {
          state.permission_industry = state.menuList
        }
      }
      state.permissions.download_all = state.download_all ? 1 : 0
      state.permissions.brand_ranking = state.brand_ranking ? 1 : 0
      state.permissions.brand_ranking_follow_state =
        state.brand_ranking_follow_state ? 1 : 0
      state.permissions.shop_ranking = state.shop_ranking ? 1 : 0
      state.permissions.shop_ranking_follow_state =
        state.shop_ranking_follow_state ? 1 : 0
      state.permissions.shop_details_promotion =
        state.shop_details_promotion ? 1 : 0
      state.permissions.pro_details =
        state.pro_details ? 1 : 0
      state.permissions.shop_details =
        state.shop_details ? 1 : 0
      state.permissions.pro_details_download =
        state.pro_details_download ? 1 : 0
      state.permissions.pro_ranking = state.pro_ranking ? 1 : 0
      state.permissions.shop_download =
        state.shop_download ? 1 : 0
      state.permissions.industry_data_sup_per = state.industry_data_sup_per
      state.innerVisible = false
    }
    // 删除传来index
    const delChild = (index) => {
      state.items.splice(index, 1)
    }
    // 点击卡片选中checkBox
    const changeCheck = (e, val) => {
      if (e) {
        state.selCard.push(val.id)
      } else {
        state.selCard = state.selCard.filter((item) => item.id !== val.id)
      }
    }
    // 新增按钮显隐联动
    const addShow = (val) => {
      state.showBtn = val
    }
    // 新增子组件
    const addChild = () => {
      state.items.push({ nowTime: new Date().getTime() })
      state.showBtn = false
    }
    // 选择行业
    const selHangye = (val) => {
      if (val === 1) {
        state.showType = false
        state.showBtn = false
      } else {
        // 选择自定义
        state.showType = true
        axios
          .get('/api/v1/basedata/get_website', {
            headers: {
              Accept: 'application/json'
            }
          })
          .then((res) => {
            state.sjqxList = res.data
          })
      }
    }
    const child = ref(null)
    // 全部开启
    const allOpen = (val) => {
      state.allOpen = val
      if (val) {
        // 判断是全部还是自定义权限，然后打开对应权限。。
        (state.disables === true) && (state.hangye = 0)
        // 只有自定义权限
        selHangye(state.disables ? 0 : 1)
      }
      state.openCheckAll = val
      state.download_all = val
      state.brand_ranking = val && state.switchList.brand_ranking !== 0
      state.brand_ranking_follow_state = val && state.switchList.brand_ranking_follow_state !== 0
      state.shop_ranking = val && state.switchList.shop_ranking !== 0
      state.shop_ranking_follow_state = val && state.switchList.shop_ranking_follow_state !== 0
      state.shop_details_promotion = val && state.switchList.shop_details_promotion !== 0
      state.pro_details = val && state.switchList.pro_details !== 0
      state.shop_details = val && state.switchList.shop_details !== 0
      state.shop_download = val && state.switchList.shop_download !== 0
      state.pro_ranking = val && state.switchList.pro_ranking !== 0
      state.pro_details_download = val && state.switchList.pro_details_download !== 0
    }
    // 打开内层dialog
    const openInnerVisible = () => {
      state.innerVisible = true
    }
    // 清空表单
    const editForm = ref(null)
    // 点击添加按钮
    const save = () => {
      editForm.value.validate((valid) => {
        if (valid) {
          axios
            .post(
              '/api/v1/customer/store',
              {
                id: props.row.id ? props.row.id : '',
                username:
                  props.title === '添加子账号'
                    ? `${ruleForm.loginName}-${props.nums}`
                    : `${ruleForm.loginName}`,
                phone: ruleForm.tel,
                password:
                  ruleForm.password === '**************'
                    ? ''
                    : ruleForm.password,
                permissions: state.permissions,
                permission_industry: state.permission_industry
                // download_all: state.download_all === true ? 1 : 0
              },
              { loading: true }
            )
            .then((res) => {
              if (res.error_code === 0) {
                ElMessage.success('保存成功')
                ctx.emit('update:modelValue', false)
                ctx.emit('getList')
              } else {
                ElMessage.error(res.error_msg)
              }
            })
        } else {
        }
      })
    }
    // 点击取消按钮
    const cancel = () => {
      ctx.emit('update:modelValue', false)
      editForm.value.resetFields()
    }
    // 表单校验
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('手机号格式有误，请重新输入'))
        }
      }
    }
    const rules = {
      tel: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { validator: validatePhone, trigger: 'blur' }
      ],
      password: [
        { required: true, message: '请输入登录密码', trigger: 'blur' },
        {
          min: 6,
          max: 15,
          message: '长度在6-15位'
        }
        // { pattern: /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,15}$/, message: '密码格式为数字、符号、字母任意两项，长度为6-15位' }
      ]
    }
    // 关闭内部弹窗
    const cancelInnerDialog = () => {
      state.innerVisible = false
    }
    // ------------------------------编辑回显的相关方法
    const resData = ref({})
    const getChildDetail = (row) => {
      ruleForm.loginName = row.name
      axios
        .get('/api/v1/customer/get_child_customer_info', {
          params: {
            customer_id: row.id
          }
        })
        .then((res) => {
          if (res.error_code === 0) {
            ruleForm.tel = res.data.phone
            resData.value = res.data
            setPermission(resData.value.customer_permission)

            saveChild()
          } else {
            ElMessage.error(res.error_msg)
          }
        })
    }
    const setPermission = (persionObj = {}) => {
      state.download_all = !!persionObj.download_all
      state.brand_ranking = !!persionObj.brand_ranking
      state.brand_ranking_follow_state = !!persionObj.brand_ranking_follow_state
      state.shop_ranking = !!persionObj.shop_ranking
      state.shop_ranking_follow_state = !!persionObj.shop_ranking_follow_state
      state.shop_details_promotion = !!persionObj.shop_details_promotion
      state.pro_details = !!persionObj.pro_details
      state.shop_details = !!persionObj.shop_details
      state.shop_download = !!persionObj.shop_download
      state.pro_ranking = !!persionObj.pro_ranking
      state.pro_details_download = !!persionObj.pro_details_download
      state.hangye = persionObj.industry_data_sup_per || 1
      if (persionObj.industry_data_sup_per === 0) {
        // 自定义------需要判断父级是否是全部权限
        // state.disables = true
        state.showType = true
        // 传给child组件的数组
        state.sendChild = resData.value.permission_industry
        // state.items = resData.permission_industry
        state.showCard = true
        state.disables = true
      }
    }
    // 防止谷歌自动填充
    const showPassword = ref(false)
    watch(
      () => ruleForm.password,
      () => {
        showPassword.value = true
      }
    )
    const phoneShowPassword = ref(true)
    const phoneFocus = () => {
      phoneShowPassword.value = false
    }
    return {
      allOpen,
      getChildDetail,
      changeCheck,
      save,
      childs,
      state,
      cancel,
      editForm,
      rules,
      ruleForm,
      openInnerVisible,
      cancelInnerDialog,
      selHangye,
      saveChild,
      addChild,
      addShow,
      delChild,
      itemCon,
      getCaption,
      selChildCard,
      delChildCard,
      iconClickAccount,
      iconClickPass,
      child,
      showPassword,
      phoneShowPassword,
      phoneFocus
    }
  }
})
</script>
<style lang="scss">
  .subfield-popper{
    .el-dialog__header {
      padding: 19px 24px;
      font-weight: 500;
      border-bottom: 1px solid rgb(233, 233, 233);
      .el-dialog__title {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .el-dialog__body {
      padding: 0 24px;
    }
  }
</style>
<style scoped lang='scss'>
.addUpdate {
  .headline {
    font-size: 16px;
    color: #242426;
    font-weight: 600;
  }
  .second-permission {
    padding-top: 14px;
    padding-left: 30px;
    padding-bottom: 10px;
  }
  :deep().el-overlay {
    .el-dialog__header {
      padding: 19px 24px;
      .el-dialog__title {
        font-size: 16px;
      }
    }
    .el-dialog__body {
      padding-bottom: 2px;
    }
    .el-dialog__footer {
      padding-right: 23px;
      padding-bottom: 24px;
      button {
        width: 80px;
      }
    }
    .el-form-item__label {
      width: 145px !important;
      padding: 0;
    }
    .el-form-item {
      margin-bottom: 13px;
      .el-button--default {
        width: 106px;
        padding: 0;
      }
      // .el-form-item__content {
      //   margin-left: 145px !important;
      // }
      .el-form-item__error {
        padding: 0;
      }
    }
  }
  .icon-zizhanghaoxinxi_bianji {
    margin-right: 10px;
    &:before {
      margin-right: 6px;
    }
  }
}
.all {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  position: absolute;
  right: 71px;
  top: 19px;
  span {
    margin-right: 12px;
  }
  .el-switch {
    height: 28px;
  }
}
.hangyepaihang {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  span {
    font-size: 14px;
    color: #242426;
  }
  :deep().el-radio-button__inner {
    background: #fff !important;
    border-color: #D0D3D9 !important;
    color: #545559 !important;
    width: 76px;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 2px;
  }
  .is-active {
    :deep().el-radio-button__inner {
      background: #FFF8F8 !important;
      border-color: #F04848 !important;
      color: #F04848 !important;
    }
  }
}
.listStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  span {
    font-size: 14px;
    color: #242426;
  }
}
.download_all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  span {
    font-size: 14px;
    color: #242426;
  }
}

:deep().el-input__clear {
  display: none;
}
:deep().el-form-item__label {
  font-size: 14px;
  color: #242426;
}
:deep().el-form-item:first-child {
  margin-top: 14px !important;
}
:deep().el-form-item:nth-of-type(4) {
  margin-top: 14px !important;
}
// :deep().el-form-item {
//   margin-bottom: 10px !important;
// }
:deep().el-form-item__content {
  text-align: start;
}
.sendBtn {
  margin-left: 10px;
}
//权限分配弹窗样式
.subfield {
  padding-bottom: 4px;
  border-bottom: 1px solid rgb(233, 233, 233);
  &:last-child {
    border-bottom: 0;
  }
  :deep().el-dialog__body {
    padding: 10px 20px 0px 20px !important;
  }
  .subfield_title {
    font-size: 16px;
    margin: 24px 0;
    display: block;
    font-weight: 600;
    i {
      color: #B4B7BF;
      margin-right: 3px;
    }
  }
  .child {
    &:first-child {
      border-radius: 2px 2px 0 0;
    }
    &:last-child {
      border-radius: 0 0 2px 2px;
    }
  }
}
:deep().el-dialog__header {
  text-align: left;
  font-size: 16px;
  color: #242426;
  font-weight: 600;
  border-bottom: 1px solid #e9ecf2;
}
:deep().el-dialog__body {
  padding: 24px 20px 30px 88px;
}
</style>
